import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import facts from './data'
import Single from './Single'

const FactBox = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: { xs: 2, sm: 5 },
      }}
    >
      <Typography align="center" variant="h3" sx={{ mb: 2 }}>
        Some Facts
      </Typography>
      {/* <Container> */}
      <Grid
        sx={{
          my: 1,
          background: 'rgba( 255, 255, 255, 0.25)',
          boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
          backdropFilter: 'blur( 4px )',
          border: '1px solid rgba( 255, 255, 255, 0.18 )',
          borderRadius: 1,
        }}
        container
        spacing={0}
        alignItems="stretch"
      >
        {facts.map((e) => (
          <Grid key={e.id} item sm={12} lg={3}>
            <Single title={e.title} description={e.description} />
          </Grid>
        ))}
      </Grid>
      {/* </Container> */}
    </Box>
  )
}

export default FactBox
