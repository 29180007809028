import AccountBoxIcon from "@mui/icons-material/AccountBox"
import CallIcon from "@mui/icons-material/Call"
import CottageIcon from "@mui/icons-material/Cottage"
import HelpCenterIcon from "@mui/icons-material/HelpCenter"
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import Inventory2Icon from "@mui/icons-material/Inventory2"

const data = [
  {
    id: 1,
    name: "Home",
    to: "/",
    icon: <CottageIcon color="primary" />,
  },
  {
    id: 2,
    name: "About",
    to: "/about",
    icon: <HelpCenterIcon color="primary" />,
  },

  {
    id: 3,
    name: "Profile",
    to: "/profile",
    icon: <AccountBoxIcon color="primary" />,
  },
  // {
  //   id: 4,
  //   name: 'Our Work',
  //   to: '/work',
  //   icon: <WorkOutlineIcon color='primary' />,
  // },
  {
    id: 5,
    name: "Products",
    to: "/category",
    icon: <Inventory2Icon color="primary" />,
  },
  {
    id: 6,
    name: "Contact",
    to: "/contact",
    icon: <CallIcon color="primary" />,
  },
]

export { data }
