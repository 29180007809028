import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined'
import BakeryDiningIcon from '@mui/icons-material/BakeryDining'

const Single = ({ title, description }) => {
  return (
    <Box
      sx={{
        p: 2,
        my: 2,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BakeryDiningIcon sx={{ fontSize: 70 }} />

      <Typography align="center" variant="h5" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <br />
      <Typography align="center" variant="subtitle2">
        {description}
      </Typography>
    </Box>
  )
}

//

export default Single
