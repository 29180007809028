const facts = [
  {
    id: 1,
    title: 'Locally Sourced',
    description:
      'The raw materials that we used to make our goods, are purely grown only in some specific areas in Bangladesh. All our products made of naturally produced fiber.',
  },
  {
    id: 2,
    title: 'Women in Work',
    description:
      'We can proudly acclaim that more than 80% of our artisans are women, who are being benefited and economically independent by their hard work and determination.',
  },
  {
    id: 3,
    title: 'Flexible MOQ',
    description:
      "We are open to both Least Container Load (LCL) and Full Container Load (FCL) shipping option as per our customer's convenience. Also we are flexible with order quantity",
  },
  {
    id: 4,
    title: 'Custom Design',
    description:
      'Since we are the manufacturer, we offer our valued customer to choose any design, color, pattern, sizes, hangtag, and logo to customize products for their brand exclusively.',
  },
]

export default facts
