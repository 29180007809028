import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Link } from "react-router-dom"

const Single = () => {
  const isSmall = useMediaQuery("(max-width:600px)")

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: isSmall ? "center" : "flex-start",
        my: 3,
        "&:hover .rootDiv": {
          width: isSmall ? "100%" : "90%",
        },
      }}
    >
      <Box
        sx={{
          my: 2,
        }}
      >
        <Typography align={isSmall ? "center" : "left"} variant="h5">
          Useful Links
        </Typography>
        <Divider
          className="rootDiv"
          sx={{
            my: 1,
            width: isSmall ? "100%" : "50%",
            height: 2,

            bgcolor: "secondary.main",
            transition: "all 0.4s ease-in-out",
          }}
        />
      </Box>

      <ButtonGroup
        orientation="vertical"
        color="secondary"
        variant="text"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: isSmall ? "center" : "flex-start",
        }}
      >
        <Button component={Link} to="/">
          Home
        </Button>

        <Button component={Link} to="/about">
          About
        </Button>

        <Button component={Link} to="/profile">
          Profile
        </Button>

        {/* {bd && (
          <Button component={Link} to='/work'>
            Our Work
          </Button>
        )} */}

        <Button component={Link} to="/category">
          Products
        </Button>

        {/* <Button component={Link} to='/profile'>
          Profile
        </Button> */}
        <Button component={Link} to="/contact">
          Contact
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default Single
